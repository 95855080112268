import { AnswerData } from "./AnswerData";

export class ChoiceTaskMotivationAnswerData extends AnswerData {
  /**
   * The slug for the choice task form.
   */
  protected endpoint = 'choiceTask';

  public formatData(answers: any) {
    const options = {};
    let versionId = -1;
    let skipped = false;
    let meta = {};

    for (const [key, answerData] of Object.entries(answers)) {
      if (key.includes('choice-task')) {
        versionId = answerData.versionId;
        meta = answerData.meta;
      }

      for (const [id, option] of Object.entries(answerData.answers)) {
        const currentData = options[option.id] || {};

        const data = {
          ...currentData,
          id: option.id,
          user_motivation: option.user_motivation,
          last_question: option.last_question,
        }

        if (key.includes('choice-task')) {
          data.step = option.step;
          data.quantity = option.quantity;
        }

        options[option.id] = data;

        if (option.skipped === true) {
          skipped = true;
        }
      }
    }

    const motivations = Object.values(options)

    return {
      blockId: this.blockId,
      answerData: {
        skipped_choice_task: skipped,
        selectedProjects: motivations,
        versionId: versionId,
        meta: meta,
      },
    };
  }
}
