<template>
  <div class="form-group form-group-matrix w-100">
    <QuestionTitle
      :question-title="currentQuestionData.title"
      :is-required="isRequired"
      :current-number="currentQuestionDisplayNumber"
      :total-number="totalQuestionNumber"
    />
    <div
      v-if="!getMobileView"
      class="d-flex pseudo-question-wrapper mb-3 mx-0 ps-0"
    >
      <div class="pseudo-question">
        <p />
      </div>
      <div
        class="answer-label-wrapper d-flex flex-row flex-nowrap justify-content-around align-items-center"
      >
        <label
          v-for="answer in answerOptions"
          :key="answer.id"
          :style="{ width: dynamicWidth }"
          class="answer-option answer-option-matrix"
        >
          {{ answer.title }}<br>
          <input
            :value="answer.id"
            name="inputMatrix"
            type="radio"
          >
        </label>
      </div>
    </div>

    <div v-if="!getMobileView">
      <div class="matrix-rows-container">
        <div
          v-for="row in visibleMatrixRows"
          :key="row.id"
          :style="getBorderColor(row.id)"
          class="form-row question-answer-wrapper p-2 d-flex question-border question-border-matrix row-12"
        >
          <div class="matrix-question col">
            <p class="answer-option">
              <LanguageSelector :to-be-selected="row.title" />
            </p>
          </div>
          <div class="answer-wrapper col align-items-center">
            <div
              v-for="answer in answerOptions"
              :key="`${row.id}-${answer.id}`"
              :style="{ width: dynamicWidth }"
              class="input-wrapper row align-items-center h-100"
              @click="userHasChosenAnswer(row.id, answer)"
            >
              <input
                :name="'question' + currentQuestionData.id + '.' + row.id"
                :value="answer.id"
                class="form-check-input answer"
                type="radio"
              >

              <div
                class="radio-answer-button-wrapper d-flex justify-content-center align-items-center"
              >
                <button
                  class="radio-answer-button d-flex justify-content-center align-items-center"
                >
                  <span
                    :class="{
                      'radio-button-active': hasAnsweredRowOptions(row.id, answer),
                    }"
                    class="radio-answer-button-inner-circle"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        :style="textOpacity"
        class="alert-required-message mt-2"
      >
        <LanguageSelector :to-be-selected="labelNegativeValidationMessage" />
      </div>
    </div>

    <div v-else>
      <div
        v-for="row in visibleMatrixRows"
        :key="row.id"
      >
        <div class="mobile-question-title">
          <LanguageSelector :to-be-selected="row.title" />
        </div>

        <div class="radio-answer-options">
          <div
            v-for="answer in answerOptions"
            :key="answer.id"
            :style="getMobileBorderColor(row.id)"
            class="radio-answer-option question-border d-flex flex-nowrap align-items-center ps-0"
            @click="userHasChosenAnswer(row.id, answer)"
          >
            <div class="">
              <input
                :name="row.id"
                :value="answer.id"
                :checked="hasAnsweredRowOptions(row.id, answer)"
                class="radio-answer-pseudo-button"
                type="radio"
              >
              <button
                class="radio-answer-button d-flex justify-content-center align-items-center ms-3"
              >
                <span
                  :class="{
                    'radio-button-active': hasAnsweredRowOptions(row.id, answer),
                  }"
                  class="radio-answer-button-inner-circle"
                />
              </button>
            </div>
            <label class="answer-option answer-option-radio-table-label h-100 ms-3">
              <LanguageSelector :to-be-selected="answer.title" />
            </label>
          </div>
        </div>

        <div
          :style="showNegativeValidationMessageMobile(row.id)"
          class="alert-required-message my-2"
        >
          <LanguageSelector :to-be-selected="labelNegativeValidationMessage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import QuestionTitle from './QuestionTitle.vue';
import { mapState } from 'pinia';
import { useInterfaceStore } from '../stores/useInterfaceStore';
import { useConsultationStore } from '../stores/useConsultationStore';

export default defineComponent({
  components: {
    QuestionTitle,
  },
  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    },
    showUserInputIsRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentQuestionNumber: {
      type: Number,
      required: true,
    },
    currentQuestionDisplayNumber: {
      type: Number,
      required: true,
    },
    totalQuestionNumber: {
      type: Number,
      required: true,
    },
    currentUserData: {
      type: Object,
      required: true,
    },
  },

  emits: ['sendCurrentUserData'],

  ERROR_BORDER: { borderColor: '#D40005' },

  data() {
    return {
      answerData: {},
      dynamicWidth: '',
    };
  },

  computed: {
    ...mapState(useInterfaceStore, [
      'getMobileView',
    ]),

    ...mapState(useConsultationStore, [
      'locale',
    ]),

    answerOptions() {
      if (!this.currentQuestionData.answer_options) {
        return [];
      }

      const answers = this.currentQuestionData.answer_options.map((answer) => {
        return {
          id: answer.id,
          title: answer.title[this.locale],
        };
      });

      return answers.filter((answer) => {
        return answer.title !== undefined;
      });
    },

    visibleMatrixRows() {
      return this.currentQuestionData.matrix_theses.filter(
        (row) => row.hidden === false
      );
    },

    isRequired() {
      return Boolean(this.currentQuestionData.required);
    },

    showAlert() {
      return Boolean(
        this.validateVisibleAnswers() === false && this.showUserInputIsRequired
      );
    },

    textOpacity() {
      return this.showUserInputIsRequired ? { opacity: '1' } : { opacity: '0' };
    },

    labelNegativeValidationMessage() {
      if (this.getMobileView) {
        return this.$t('pagination.input_matrix_validation_required_mobile')
      }

      return this.$t('pagination.input_matrix_validation_required')
    },
  },

  watch: {
    visibleMatrixRows() {
      this.validateVisibleAnswers();
      this.$emit('sendCurrentUserData', this.answerData);
    },
  },

  created() {
    const answerData = this.currentUserData ? { ...this.currentUserData } : {};

    const optionsCount = this.answerOptions.length;
    const rowCount = this.currentQuestionData.matrix_theses.length;

    if (
      answerData.user_has_answered_array &&
      answerData.user_has_answered_array.some((a) => a === true)
    ) {
      //
    } else {
      answerData.user_has_answered = false;
      answerData.user_has_answered_array = Array(rowCount).fill(false);
      answerData.user_answered_array = Array(rowCount).fill(false);
      answerData.raw_input_matrix_table = {};
      answerData.answer_id = [];
      answerData.answer_map = {};

      this.currentQuestionData.matrix_theses.forEach((row) => {
        answerData.raw_input_matrix_table[row.id] = null;
      });
    }

    answerData.id = this.currentQuestionData.id;
    answerData.title = this.currentQuestionData.title;
    answerData.required = this.isRequired;
    answerData.field_type = this.currentQuestionData.field_type;
    answerData.question_number = this.currentQuestionNumber;
    answerData.answer_options = this.answerOptions;

    answerData.matrix_theses = this.currentQuestionData.matrix_theses;

    this.answerData = answerData;

    this.$emit('sendCurrentUserData', this.answerData);

    this.dynamicWidth = String(155 / optionsCount) + '%';
  },

  methods: {
    validateVisibleAnswers() {
      let hasAnsweredAll = true;

      for (const row of this.visibleMatrixRows) {
        const answer = this.answerData.raw_input_matrix_table[row.id];

        if (answer === null) {
          hasAnsweredAll = false;
          break;
        }
      }

      this.answerData.user_has_answered = hasAnsweredAll;
      return this.answerData.user_has_answered;
    },

    userHasChosenAnswer(rowId, answer) {
      // Reset all answer options to false, such that only one answer option can be selected next
      this.answerData.raw_input_matrix_table[rowId] = answer.id;

      const rowIndex = this.answerData.matrix_theses.findIndex(
        ({ id }) => id === rowId
      );

      this.answerData.user_has_answered_array[rowIndex] = true;
      this.answerData.user_answered_array[rowIndex] = answer.title;

      this.validateVisibleAnswers();

      this.answerData.answer_id = [];
      this.answerData.answer_map = {...this.answerData.raw_input_matrix_table};

      Object.values(this.answerData.raw_input_matrix_table).forEach((id) => {
        if (id !== null) {
          this.answerData.answer_id.push(id);
        }
      });

      this.$emit('sendCurrentUserData', this.answerData);
    },

    hasAnsweredRowOptions(rowId, answer) {
      return this.answerData.raw_input_matrix_table[rowId] === answer.id;
    },

    rowErrorState(rowId) {
      if (!this.showUserInputIsRequired) {
        return false;
      }

      const { raw_input_matrix_table } = this.answerData;
      return raw_input_matrix_table[rowId] === null;
    },

    getBorderColor(rowId) {
      if (this.rowErrorState(rowId)) {
        return this.$options.ERROR_BORDER;
      }
    },

    getMobileBorderColor(rowId) {
      if (this.rowErrorState(rowId)) {
        return this.$options.ERROR_BORDER;
      }
    },

    showNegativeValidationMessageMobile(rowId) {
      const { raw_input_matrix_table } = this.answerData;
      const isChecked = raw_input_matrix_table[rowId] !== null;

      if (!isChecked && this.showUserInputIsRequired) {
        return { opacity: 1 };
      }

      return { opacity: 0 };
    },
  },
});
</script>

<style scoped>
.pseudo-question-wrapper {
  border-radius: 10px;
  flex-wrap: nowrap;
}
.pseudo-question {
  width: 40%;
}

.answer-label-wrapper {
  text-align: center;
  width: 60%;
}

.answer-label-wrapper input {
  display: none;
}

.question-answer-wrapper {
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 1px 25px rgba(212, 212, 212, 0.17);
}

.question-answer-wrapper:focus-within,
.question-answer-wrapper:hover {
  background-color: #e7eaec;
}

.matrix-question {
  max-width: 40%;
}

.matrix-question p {
  margin: 0.5rem 0;
}

.matrix-rows-container > div:not(:last-child) {
  margin-bottom: 1rem;
}

.answer-wrapper {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.input-wrapper {
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.input-wrapper input {
  position: absolute;
  display: none;
  opacity: 0;
}

.mobile-question-title {
  font-weight: bold;
  color: var(--text-color-content);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
</style>
