import * as Sentry from '@sentry/vue';
import { getCurrentEnvironment } from './settings';

export function initializeSentry(app, router) {
  Sentry.init({
    app,
    dsn: 'https://3426343e07ec4613b9f52a02d7fbc355@o976881.ingest.sentry.io/5933281',
    environment: getCurrentEnvironment(),
    ignoreErrors: ['javaEnabled'], // See: https://github.com/matomo-org/matomo/issues/22836
    integrations: [
      // new Integrations.BrowserTracing({
      //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // }),
      Sentry.browserTracingIntegration({
        router: router,
        enableInp: true,
      }),
    ],
    tracesSampleRate: 1,
    // beforeSend: (event, hint) => {
    //   if (typeof hint.originalException !== 'string') {
    //     const { response } = hint.originalException;

    //     // Ignore 404 errors
    //     if (response && response.status && response.status === 404) {
    //       return null;
    //     }

    //     // Ignore not made HTTP request errors
    //     if (response && response.status && response.status === 0) {
    //       return null;
    //     }
    //   }

    //   return event;
    // },
  });
}
